<template>
  <section class="banner-section">
    <div class="banner-image" :class="siteCurrentLang"></div>
  </section>
</template>

<script>
export default {
  name: "BannerComponent",
  props: { siteCurrentLang: String },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.banner-section {
  position: inherit;
  z-index: 3;
  .banner-image {
    //background-image: url("../../assets/banner.png");
    background-size: cover;
    background-position: top center;
    height: auto;
    width: 100%;
    padding-top: 44.5%;
    // min-height: 848px;
    &.ka {
      background-image: url("../../assets/banner.png");
    }
    &.en {
      background-image: url("../../assets/banner-en.png");
    }
  }
}
</style>
