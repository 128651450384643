<template>
  <div class="eb-main-wrapper">
    <BannerComponent :siteCurrentLang=siteCurrentLang></BannerComponent>
    <div class="marquee">
      <p class="marquee-text">
        <span class="eb-font-bold text-uppercase"><span v-if="siteCurrentLang == 'ka'" class="chakra-bold">7-8</span> {{generateText.October}} <span v-if="siteCurrentLang == 'en'" class="chakra-bold">7-8</span> </span>
        <span class="stroke eb-font-bold text-uppercase"><span v-if="siteCurrentLang == 'ka'" class="stroke chakra-bold">7-8</span> {{generateText.October}} <span v-if="siteCurrentLang == 'en'" class="stroke chakra-bold">7-8</span> </span>
      </p>
    </div>
    <section class="eb-section">
      <div class="content-wrapper">
        <div class="main-title">
          <div class="line"></div>
          <p class="eb-font-roman text-font24">
            <span class='chakra-regular'>Degamefi WEB3</span>
            {{generateText.MainHead}}
          </p>
          <div class="line"></div>
        </div>
        <div class="offers-box">
          <p class="isTitle eb-font-roman text-font24 text-uppercase"> {{generateText.OffersHead}}</p>
          <p class="isDesc eb-font-roman text-font24 text-uppercase"> {{generateText.OffersDesc}}</p>
          <div class="offers_items">
            <div class="offers_item terms">
              <div id="offersTermsHead" class="head eb-font-medium text-font24 text-uppercase"> {{generateText.Terms}}
                <div class="cube topright"></div>
                <a class="terms-info-popover" tabindex="0" role="button" v-b-popover="termsInfoConfig">
                  <img :src="require('@/assets/information-button-black.png')" alt="" class="terms-info-icon"></a>
                <div class="cube bottomright"></div>
              </div>
              <div class="body">
                <div class="cube topright"></div>
                <div class="list-items">
                  <div class="isLeft">
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">{{generateText.TournamentDates}}</p>
                      <p class="eb-font-bold text-font16">{{generateText.TournamentDatesDesc}}</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">{{generateText.Participants}}</p>
                      <p class="eb-font-bold text-font16">{{generateText.ParticipantsDesc}}</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">{{generateText.RegistrationHead}}</p>
                      <p class="eb-font-bold text-font16">{{generateText.RegistrationDesc}}</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">{{generateText.Format}}</p>
                      <p class="eb-font-bold text-font16">{{generateText.FormatDesc}}</p>
                    </div>
                  </div>
                  <div class="isRight ">
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">{{generateText.HalfDuration}}</p>
                      <p class="eb-font-bold text-font16">{{generateText.HalfDurationDesc}}</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">Competitive mode:</p>
                      <p class="eb-font-bold text-font16">On</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">Defense:</p>
                      <p class="eb-font-bold text-font16">Tactical</p>
                    </div>
                    <div class="list-item">
                      <p class="eb-font-roman text-font16">Player OVR:</p>
                      <p class="eb-font-bold text-font16">95</p>
                    </div>
                  </div>
                </div>
                <div class="cube bottomright"></div>
              </div>
            </div>
            <div class="offers_item first">
              <div class="head eb-font-medium text-font24 text-uppercase">
                <div class="cube topright"></div>
                <p>{{generateText.Prizes}}</p>
                <div class="cube bottomright"></div>
              </div>
              <div class="body">
                <div class="cube topright"></div>
                <div class="cube bottomright"></div>
                <h3 class="eb-font-black text-uppercase">I {{generateText.Place}}</h3>
                <div class="prize-info">
                  <div class="prize-img">
                    <img :src="require('@/assets/prize-1.png')" alt="">
                  </div>
                  <div class="prize-desc">
                    <p class="title eb-font-medium text-font16">Unu Electric Scooter</p>
                    <div class="desc eb-font-roman text-font14">
                      <p>{{generateText.MaxSpeed}}</p>
                      <p>{{generateText.Range}}</p>
                      <p>{{generateText.Weight}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="offers_item second">
              <div class="head eb-font-medium text-font24 text-uppercase"></div>
              <div class="body">
                <div class="cube topleft"></div>
                <div class="cube bottomleft"></div>
                <h3 class="eb-font-black text-uppercase">II {{generateText.Place}}</h3>
                <div class="prize-info">
                  <div class="prize-img">
                    <img :src="require('@/assets/prize-2.png')" alt="">
                  </div>
                  <div class="prize-desc">
                    <p class="title eb-font-medium text-font16">Sony PlayStation PS5</p>
                    <div class="desc eb-font-roman text-font14">
                      <p>{{generateText.Color}}</p>
                      <p>{{generateText.ReleaseDate}}</p>
                      <p>{{generateText.HardDrive}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="auth-btn" id="authBtn" :class="{started: AuthStarted && !AuthFinished}" @click="AuthStarted && !AuthFinished ? GetAuth() : null">
            <span class="cube topleft"></span>
            <span class="cube topright"></span>
            <p class="eb-font-medium text-font14">{{generateText.Registration}}
              <a class="auth-info-popover" tabindex="0" role="button" v-b-popover="authInfoConfig" v-if="!AuthStarted && !AuthFinished || AuthStarted && AuthFinished">
                <img :src="require('@/assets/information-button-white.png')" alt="" class="auth-info-icon"></a>
            </p>
            <span class="cube bottomleft"></span>
            <span class="cube bottomright"></span>
          </div>
        </div>
        <div class="articles-box">
          <div class="line"></div>
          <p class="isTitle eb-font-roman text-font24 text-uppercase">{{generateText.ArticlesHead}}</p>
          <div class="articles-items">
            <a class="articles-item" target="_blank" href="https://europop.ge/article/50904-csgo-rogor-moigo-team-ufg-m-evropabetis-zamtris-tasi">
              <div class="articles-img">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <img src="../../assets/articles/article-1.jpg" alt />
              </div>
              <p class="img-title text-font16 eb-font-roman">
                {{generateText.ArticleDesc1}}
              </p>

            </a>
            <a class="articles-item middle" target="_blank" href="https://europop.ge/article/50479-evropabetis-zamtris-tasi-csgo-s-dramatuli-pinali-ufg-m-moigo">
              <div class="articles-img">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <img src="../../assets/articles/article-2.jpg" alt />
              </div>
              <p class="img-title text-font16 eb-font-roman">
                {{generateText.ArticleDesc2}}
              </p>
            </a>
            <a class="articles-item" target="_blank" href="https://europop.ge/article/50436-evropabetis-zamtris-tasi-gamorcheuli-dzmebi-nanavebis-pinali-gamodga">
              <div class="articles-img">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <img src="../../assets/articles/article-3.jpg" alt />
              </div>
              <p class="img-title text-font16 eb-font-roman">
                {{generateText.ArticleDesc3}}
              </p>

            </a>
          </div>

        </div>

        <div class="video-box" v-if="IsLiveTime">
          <div class="line"></div>
          <p class="isTitle eb-font-roman text-font24 text-uppercase">{{generateText.VideoArticleiHead}}</p>

          <div class="video-box-inner">
            <a class="isArticle" target="_blank" href="https://europop.ge/article/50904-csgo-rogor-moigo-team-ufg-m-evropabetis-zamtris-tasi">
              <div class="articles-img">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <img src="../../assets/video/article-img.jpg" alt />
              </div>
              <p class="img-title text-font16 eb-font-roman">
                {{generateText.VideoArticleiDesc}}
              </p>

            </a>
            <div class="isVideo">
              <div class="cube topleft"></div>
              <div class="cube topright"></div>
              <div class="cube bottomleft"></div>
              <div class="cube bottomright"></div>
              <LiveVideoComponent></LiveVideoComponent>
            </div>

          </div>
        </div>

        <div class="footer">
          <div class="isLogo">
            <a href="https://www.europebet.com/ka" target="_blank"><img :src="require('@/assets/eb-logo.png')" alt=""></a>
            <div class="lang-btns">
              <div class="lang-btn lang-btn-ka" :class="{active:siteCurrentLang == 'ka'}" @click="setSiteLang('ka')">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <div class="isContent">
                  <p class="eb-font-roman text-font16 text-uppercase">Ge</p>
                  <img :src="require('@/assets/lang-ka.png')" alt="">
                </div>
              </div>
              <div class="lang-btn lang-btn-en" :class="{active:siteCurrentLang == 'en'}" @click="setSiteLang('en')">
                <div class="cube topleft"></div>
                <div class="cube topright"></div>
                <div class="cube bottomleft"></div>
                <div class="cube bottomright"></div>
                <div class="isContent">
                  <p class="eb-font-roman text-font16 text-uppercase">En</p>
                  <img :src="require('@/assets/lang-en.png')" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="isIcons">
            <div class="icons-items">
              <a href="https://youtube.com/@europebet" target="_blank"><img :src="require('@/assets/youtube-icon.png')" alt=""></a>
              <a href="https://instagram.com/europebet" target="_blank"> <img :src="require('@/assets/instagram-icon.png')" alt=""></a>
              <a href="https://www.facebook.com/europebet" target="_blank"><img :src="require('@/assets/facebook-icon.png')" alt=""></a>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-block" v-if="isMobile"></div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContentService from "@/core/services/content.service";
import CoreService from "@/core/services/core.service";
import PostMessageService from "@/core/services/post-message.service";
import BannerComponent from "@/components/banner/index.vue";

import LiveVideoComponent from "@/components/live-video/index.vue";
import moment from "moment";

export default {
  components: {
    BannerComponent,
    ContentService,
    PostMessageService,
    LiveVideoComponent,
  },

  data() {
    return {
      IsLiveTime: false,
      IsAuthorized: false,
      dateNow: moment().format("YYYY-MM-DD"), //"2022-08-04"
      AuthFinished: false,
      AuthStarted: false,
      siteCurrentLang: "ka",
    };
  },
  methods: {
    getIsLive() {
      this.IsLiveTime = moment().isBetween(
        moment("20-10-2024 19:00:00", "DD-MM-YYYY hh:mm:ss"),
        moment("21-10-2024 08:00:00", "DD-MM-YYYY hh:mm:ss")
      );
      // console.log("IsLiveTime", this.IsLiveTime);
    },
    GetAuth() {
      console.log("Registration");
      window.open(
        "https://docs.google.com/forms/d/1hUiUIt4_1pGTH3-EVlA8lIyF-SKHtFkLLw3Ymv25B3w/edit",
        "_blank"
      );
      // window.location.href = "#";
    },
    setSiteLang(lang) {
      this.siteCurrentLang = lang;
    },
  },
  computed: {
    isMobile() {
      return CoreService.isMobile();
    },

    generateText() {
      switch (this.siteCurrentLang) {
        case "ka":
          return {
            October: "ოქტომბერი",
            MainHead:
              "- უდიდესი კონფერენცია კავკასიის რეგიონში, რომელიც წამყვანი საერთაშორისო და მოწინავე კომპანიების სპიკერებსა და პროექტებს აერთიანებს",
            OffersHead: "ჩემპიონატი FIFA 23-ში",
            OffersDesc:
              "ევროპაბეთი ქართული კიბერსპორტის მხარდასაჭერად მორიგ ივენთს გამართავს",
            Terms: "წესები და პირობები",
            TournamentDates: "ტურნირი შედგება: ",
            TournamentDatesDesc: "7 და 8 ოქტომბერს",
            Participants: "მონაწილეობს: ",
            ParticipantsDesc: "32 მოთამაშე",
            RegistrationHead: "რეგისტრაცია: ",
            RegistrationDesc: "1-დან 6 ოქტომბრამდე",
            Format: "ფორმატი: ",
            FormatDesc: "ჯგუფი/ფლეიოფი",
            HalfDuration: "ტაიმის ხანგრძლივობა: ",
            HalfDurationDesc: "5 წთ.",
            Registration: "რეგისტრაცია",
            ExampleTerms:
              "ტურნირში მონაწილეობის მიღება შეუძლია 32 მსურველს, რომელიც შეიძენს კონფერენციის ბილეთს და პირველი გაივლის FIFA23-ის ჩემპიონატზე რეგისტრაციას.",
            Prizes: "პრიზები",
            Place: "ადგილი",
            MaxSpeed: "მაქს. სიჩქარე: 45 კმ/სთ",
            Range: "დაფარვის მანძილი: 50 კმ",
            Weight: "სკუტერის წონა: 58 კგ",
            Color: "ფერი: თეთრი",
            ReleaseDate: "გამოშვების წელი: 2020",
            HardDrive: "მყარი დისკი: 825GB",
            ExampleAuthStart: "რეგისტრაცია იწყება 1 ოქტომბერს.",
            ExampleAuthFinish: "რეგისტრაცია დასრულებულია.",
            ArticlesHead:
              "ევროპაბეთი ქართული კიბერსპორტის ჩემპიონატის გენერალური სპონსორია",
            ArticleDesc1:
              "ევროპაბეთის ზამთრის თასი | როგორ მოიგო TEAM UFG-მ ევროპაბეთის ზამთრის თასი",
            ArticleDesc2:
              "ევროპაბეთის ზამთრის თასი | CS:GO-ს დრამატული ფინალი UFG-მ მოიგო",
            ArticleDesc3:
              "ევროპაბეთის ზამთრის თასი | გამორჩეული ძმები ნანავების ფინალი გამოდგა",
            VideoArticleiHead: "DEGAMEFI FIFA 23 შეჯამება",
            VideoArticleiDesc:
              "ევროპაბეთის ზამთრის თასი | როგორ მოიგო TEAM UFG-მ ევროპაბეთის ზამთრის თასი",
          };

        case "en":
          return {
            October: "October",
            MainHead:
              "- the largest conference in the Caucasus region, which brings together speakers and projects of leading international companies",
            OffersHead: "FIFA 23 tournament",
            OffersDesc:
              "Europebet will hold another event in support of Georgian cybersport",
            Terms: "Terms & conditions",
            TournamentDates: "Tournament dates: ",
            TournamentDatesDesc: "October 7 & 8",
            Participants: "Participants: ",
            ParticipantsDesc: "32 players",
            RegistrationHead: "Registration: ",
            RegistrationDesc: "October 1-6",
            Format: "Format: ",
            FormatDesc: "Group stage/playoff",
            HalfDuration: "Half duration: ",
            HalfDurationDesc: "5 min",
            Registration: "Registration",
            ExampleTerms:
              "32 applicants who purchase a conference ticket and are the first to register for the FIFA23 tournament, can participate in the tournament.",
            Prizes: "Prizes",
            Place: "place",
            MaxSpeed: "Max. speed: 45 kmph",
            Range: "Range: 50 km",
            Weight: "Weight: 58 kg",
            Color: "Color: White",
            ReleaseDate: "Release date: 2020",
            HardDrive: "Hard drive: 825GB",
            ExampleAuthStart: "Registration starts on October 1.",
            ExampleAuthFinish: "The registration is complete.",
            ArticlesHead:
              "Europebet is the general sponsor of the Georgian eSports Championship",
            ArticleDesc1:
              "europebet Winter Cup | How TEAM UFG won europebet Winter Cup",
            ArticleDesc2: "europebet Winter Cup | UFG won CS:GO dramatic final",
            ArticleDesc3: "europebet Winter Cup |Nanava brothers final",
            VideoArticleiHead: "DEGAMEFI FIFA 23 summery",
            VideoArticleiDesc:
              "europebet Winter Cup | How TEAM UFG won europebet Winter Cup",
          };

        default:
          break;
      }
    },
    termsInfoConfig() {
      return {
        html: true,
        container: "offersTermsHead",
        trigger: "hover focus",
        placement: "bottomleft",
        title: "",
        content: this.generateText.ExampleTerms,
        ref: "popover",
      };
    },
    authInfoConfig() {
      return {
        html: true,
        container: "authBtn",
        trigger: "hover focus",
        placement: "bottomleft",
        title: "",
        content:
          !this.AuthStarted && !this.AuthFinished
            ? this.generateText.ExampleAuthStart
            : this.AuthStarted && !this.AuthFinished
            ? ""
            : this.AuthStarted && this.AuthFinished
            ? this.generateText.ExampleAuthFinish
            : "",
        ref: "popover",
      };
    },
  },
  watch: {},
  created() {},
  beforeMount() {
    this.getIsLive();
    this.AuthStarted = moment(
      "01-10-2023 00:00:00",
      "DD-MM-YYYY hh:mm"
    ).isBefore(new Date());
    this.AuthFinished = moment(
      "06-10-2023 23:59:00",
      "DD-MM-YYYY hh:mm"
    ).isBefore(new Date());
  },
  mounted() {
    PostMessageService.LoadingFinished();
    // setInterval(() => {
    //   this.getIsLive();
    // }, 30000);
  },
  destroyed() {},
};
</script>
<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.text-font13 {
  font-size: 13px;
}
.eb-section {
  padding: 0px 23px 0px 23px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: 0;
    right: 0px;
    bottom: 0;
    background: url("../../assets/content-bg.png") no-repeat;
    background-position: left top 24px;
    background-size: 100%;
  }
  @media only screen and (max-width: 768px) {
    // padding: 0px 23px 58px 23px;
  }
}
.marquee {
  margin-top: -15.5%;
  margin-bottom: 64px;
  position: relative;
  z-index: 999999;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 18px;
  }
}
.marquee-text {
  font-size: 128px;
  display: flex;
  align-items: center;
  animation: text 15s infinite linear;
  padding-left: 100%;
  white-space: nowrap;
  height: 166px;
  span {
    color: #fff;
    &.stroke {
      color: transparent;
      -webkit-text-stroke: 1px #fff;
      margin-left: 65px;
    }
  }
  @media only screen and (max-width: 1200px) {
    font-size: 38px;
    height: 49px;
    span {
      &.stroke {
        margin-left: 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    animation: textMob 15s infinite linear;
  }
}
@keyframes text {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-250%, 0);
  }
}
@keyframes textMob {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-270%, 0);
  }
}

.content-wrapper {
  width: 100%;
  max-width: 1432px;
  margin: 0 auto;
  position: relative;
  @media only screen and (max-width: 1366px) {
    max-width: 945px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 600px;
  }
}
.main-title {
  margin-bottom: 64px;
  text-align: center;
  color: #e2e2e2;
  .line {
    width: 100%;
    height: 2px;
    background-color: #65616b;
  }
  p {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
    p {
      font-size: 18px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
  @media only screen and (max-width: 768px) {
    p {
      font-size: 14px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.offers-box {
  margin-bottom: 84px;

  .isTitle {
    text-align: center;
    color: #fff;
  }
  .isDesc {
    text-align: center;
    color: #fff;
  }
  .offers_items {
    margin-top: 64px;
    margin-bottom: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    .offers_item {
      width: calc(100% / 3);
      max-width: calc(100% / 3);
      background-color: #fff;
      min-height: 436px;
      display: flex;
      flex-direction: column;
      position: relative;

      .head {
        color: #6c32c2;
        border-bottom: 4px solid #171719;
        min-height: 84px;
        padding-left: 42px;
        display: flex;
        align-items: center;
        position: relative;
      }
      .body {
        color: #1c1d1e;
        flex: 1;
        position: relative;
      }
      &.terms {
        border-right: 4px solid #171719;
        .head {
          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            top: 0;
            left: 0;
          }
          &:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            bottom: 0;
            left: 0;
          }
          .cube {
            display: none;
          }
          .terms-info-popover {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .popover {
            background-color: #323638;
            padding: 16px 28px;
            border-radius: 12px;
            max-width: 250px;
            top: 0px !important;
            left: 8px !important;
            z-index: 99999;
            font-family: inherit;
            &.bs-popover-top {
              top: -10px !important;
              .arrow {
                left: unset !important;
                right: 5px !important ;
                margin-left: -8px;
                bottom: -17px;
                border: 8px solid transparent;
                border-top: 12px solid #323638;
              }
            }

            &.bs-popover-bottom {
              .arrow {
                margin-left: -17px;
                border-top-width: 0;
                border-bottom-color: #323638;
                top: -7px;

                &::after {
                  content: " ";
                  top: 7px;
                  margin-left: -10px;
                  border-top-width: 0;
                  border-bottom-color: transparent;
                  transform: rotate(125deg);
                }
              }
            }
            .popover-header {
              background-color: transparent;
              border-bottom-color: #323638;
              font-size: 14px !important;
              color: #fff;
              padding-bottom: 0;
              margin-top: 0;
              margin-bottom: 5px;
              line-height: 1.2;
              font-weight: normal;
            }
            .popover-body {
              font-family: "Helvetica-Medium" !important;
              font-size: 13px !important;
              color: #fff;
              line-height: 1.2;
            }
          }
          html:lang(ru) {
            .popover {
              .popover-header {
                font-family: "Helvetica-Black-Ru" !important;
              }

              .popover-body {
                font-family: "Helvetica-Roman-Ru" !important;
              }
            }
          }
        }
        .body {
          .cube {
            display: none;
          }
          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            top: 0;
            left: 0;
          }
          &:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            bottom: 0;
            left: 0;
          }
          display: flex;
          align-items: center;
          .list-items {
            padding: 24px 31px 24px 42px;
            width: 100%;
            display: block;
            .list-item {
              p {
                &:last-of-type {
                  text-align: right;
                }
              }
            }
            .isLeft {
              flex: unset;
              padding-right: 0px;
              position: relative;
              &:after {
                content: "";
                display: none;
              }
            }
            .isRight {
              flex: unset;
              padding-left: 0px;
            }
            .list-item {
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
      &.first {
        .head {
          .cube {
            display: none;
          }
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
        .body {
          background: url("../../assets/prize-1-bg.png") no-repeat;
          background-position: bottom left;
          background-size: 100% 330px;
          .cube {
            display: none;
          }
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
          h3 {
            font-size: 72px;
            color: #f05a22;
            margin: 0;
            margin-top: 20px;
            text-align: center;
          }
          .prize-info {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 5px;
            .prize-img {
              img {
                max-width: 100%;
                height: 205px;
              }
            }
            .prize-desc {
              padding-left: 8px;
              .title {
                color: #6c32c2;
                margin-bottom: 12px;
              }
              .desc {
                color: #1c1d1e;
              }
            }
          }
        }
      }
      &.second {
        .head {
          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            top: 0;
            right: 0;
          }
          &:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            bottom: 0;
            right: 0;
          }
        }
        .body {
          background: url("../../assets/prize-2-bg.png") no-repeat;
          background-position: center;
          background-size: 100% 100%;
          border-left: 4px solid #171719;
          .cube {
            display: none;
          }
          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            top: 0;
            right: 0;
          }
          &:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            display: inline-block;
            background-color: #171719;
            bottom: 0;
            right: 0;
          }
          h3 {
            font-size: 72px;
            color: #6c32c2;
            margin: 0;
            margin-top: 20px;
            text-align: center;
          }
          .prize-info {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 5px;
            .prize-img {
              img {
                max-width: 100%;
                height: 205px;
              }
            }
            .prize-desc {
              padding-left: 8px;
              .title {
                color: #6c32c2;
                margin-bottom: 12px;
              }
              .desc {
                color: #1c1d1e;
              }
            }
          }
        }
      }
    }
  }
  .auth-btn {
    text-align: center;
    background-color: #65616b;
    width: 188px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    color: #fff;

    .auth-info-popover {
      cursor: pointer;
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .popover {
      background-color: #fff;
      padding: 16px 28px;
      border-radius: 12px;
      max-width: 400px;
      min-width: 300px;
      top: 0px !important;
      left: 8px !important;
      z-index: 99999;
      font-family: inherit;
      &.bs-popover-top {
        top: -10px !important;
        .arrow {
          //left: unset !important;
          //right: 5px !important ;
          margin-left: -10px;
          bottom: -17px;
          border: 8px solid transparent;
          border-top: 12px solid #fff;
        }
      }

      &.bs-popover-bottom {
        .arrow {
          margin-left: -17px;
          border-top-width: 0;
          border-bottom-color: #fff;
          top: -7px;

          &::after {
            content: " ";
            top: 7px;
            margin-left: -10px;
            border-top-width: 0;
            border-bottom-color: transparent;
            transform: rotate(125deg);
          }
        }
      }
      .popover-header {
        background-color: transparent;
        border-bottom-color: #fff;
        font-size: 14px !important;
        color: #171719;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 1.2;
        font-weight: normal;
      }
      .popover-body {
        font-family: "Helvetica-Medium" !important;
        font-size: 13px !important;
        color: #171719;
        line-height: 1.2;
        // white-space: nowrap;
      }
    }
    html:lang(ru) {
      .popover {
        .popover-header {
          font-family: "Helvetica-Black-Ru" !important;
        }

        .popover-body {
          font-family: "Helvetica-Roman-Ru" !important;
        }
      }
    }
    .cube {
      background-color: #171719;
      width: 8px;
      height: 8px;
      position: absolute;
      &.topleft {
        left: 0;
        top: 0;
      }
      &.topright {
        right: 0;
        top: 0;
      }
      &.bottomleft {
        left: 0;
        bottom: 0;
      }
      &.bottomright {
        right: 0;
        bottom: 0;
      }
    }
    &.started {
      background-color: #6c32c2;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1366px) {
    margin-bottom: 64px;
    .offers_items {
      flex-wrap: wrap;
      .offers_item {
        width: calc(100% / 2);
        max-width: 50%;
        min-height: auto;
        .head {
          justify-content: center;
          padding-left: 0px;
        }

        &.terms {
          width: calc(100% / 1);
          max-width: 100%;
          border-right: 0px none;
          .head {
            .cube {
              display: inline-block;
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;

              &.topright {
                top: 0;
                right: 0;
              }
              &.bottomright {
                bottom: 0;
                right: 0;
              }
            }
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              left: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              left: 0;
            }
            .terms-info-popover {
              cursor: pointer;
              position: absolute;
              top: calc(50% - 12px);
              right: 25px;
            }
          }
          .body {
            .cube {
              display: inline-block;
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;

              &.topright {
                top: 0;
                right: 0;
              }
              &.bottomright {
                bottom: 0;
                right: 0;
              }
            }
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              left: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              left: 0;
            }
            display: flex;
            align-items: center;
            border-bottom: 4px solid #171719;
            .list-items {
              display: flex;
              justify-content: center;
              align-items: stretch;
              padding: 58px 15px 58px 15px;
              .isLeft {
                flex: 1;
                padding-right: 50px;
                position: relative;
                &:after {
                  content: "";
                  width: 1px;
                  height: 100%;
                  display: inline-block;
                  background-color: rgba(23, 23, 25, 0.2);
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
              .isRight {
                flex: 1;
                padding-left: 50px;
              }
            }
          }
        }
        &.first {
          .head {
            width: 200%;
            z-index: 99;
            .cube {
              display: none;
            }
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              left: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              left: 0;
            }
          }
          .body {
            background: url("../../assets/prize-1-bg.png") no-repeat;
            background-position: bottom right;
            background-size: 97% 315px;
            .cube {
              display: none;
            }
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              left: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              left: 0;
            }
          }
        }
        &.second {
          .head {
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              right: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              right: 0;
            }
          }
          .body {
            background: url("../../assets/prize-2-bg.png") no-repeat;
            background-position: center;
            background-size: 100% 100%;
            border-left: 4px solid #171719;
            &:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              top: 0;
              right: 0;
            }
            &:after {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
    .isTitle {
      font-size: 20px;
    }
    .isDesc {
      font-size: 20px;
    }
    .offers_items {
      flex-wrap: wrap;
      margin-top: 24px;
      margin-bottom: 24px;
      .offers_item {
        width: calc(100% / 1);
        max-width: 600px;

        &.terms {
          width: calc(100% / 1);
          max-width: 600px;

          .head {
            font-size: 18px;
          }
          .body {
            min-height: 238px;
            .list-items {
              padding: 24px 15px 24px 15px;
              flex-direction: column;
              .list-item {
                margin-bottom: 5px;
                p {
                  font-size: 13px;
                }
              }
              .isLeft {
                padding-right: 0px;
                &:after {
                  display: none;
                }
              }
              .isRight {
                padding-left: 0px;
              }
            }
          }
        }
        &.first {
          border-bottom: 4px solid #171719;
          .head {
            width: 100%;
            font-size: 18px;
            .cube {
              display: inline-block;
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;

              &.topright {
                top: 0;
                right: 0;
              }
              &.bottomright {
                bottom: 0;
                right: 0;
              }
            }
          }
          .body {
            background: none;
            background-position: center;
            background-size: 100% 100%;
            min-height: 238px;
            .cube {
              display: inline-block;
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;

              &.topright {
                top: 0;
                right: 0;
              }
              &.bottomright {
                bottom: 0;
                right: 0;
              }
            }

            h3 {
              font-size: 48px;
            }
            .prize-info {
              .prize-img {
                img {
                  max-width: 100%;
                  height: 196px;
                }
              }
              .prize-desc {
                .title {
                  font-size: 14px;
                  margin-bottom: 11px;
                }
                .desc {
                  font-size: 12px;
                }
              }
            }
          }
        }
        &.second {
          .head {
            display: none;
            font-size: 18px;
          }
          .body {
            background: none;
            background-position: center;
            background-size: 100% 100%;
            min-height: 238px;
            .cube {
              display: inline-block;
              position: absolute;
              width: 18px;
              height: 18px;
              display: inline-block;
              background-color: #171719;

              &.topleft {
                top: 0;
                left: 0;
              }
              &.bottomleft {
                bottom: 0;
                left: 0;
              }
            }
            border-left: 0px none;

            h3 {
              font-size: 48px;
            }
            .prize-info {
              .prize-img {
                img {
                  max-width: 100%;
                  height: 196px;
                }
              }
              .prize-desc {
                .title {
                  font-size: 14px;
                  margin-bottom: 11px;
                }
                .desc {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .isTitle {
      font-size: 16px;
    }
    .isDesc {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 600px) {
    .offers_items {
      .offers_item {
        &.first {
          .body {
            .prize-info {
              .prize-img {
                img {
                  max-width: 100%;
                  height: 166px;
                }
              }
            }
          }
        }
        &.second {
          .body {
            .prize-info {
              .prize-img {
                img {
                  max-width: 100%;
                  height: 166px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 374px) {
    .offers_items {
      .offers_item {
        &.terms {
          .head {
            font-size: 16px;

            .terms-info-popover {
              right: 15px;
            }
          }
          .body {
            h3 {
              font-size: 44px;
            }
          }
        }
        &.first {
          .head {
            font-size: 16px;
          }
          .body {
            h3 {
              font-size: 44px;
            }
          }
        }
        &.second {
          .head {
            font-size: 16px;
          }
          .body {
            h3 {
              font-size: 44px;
            }
          }
        }
      }
    }
  }
}

.articles-box {
  position: relative;
  margin-bottom: 84px;
  .line {
    width: 100%;
    height: 2px;
    background-color: #65616b;
  }
  .isTitle {
    margin-top: 84px;
    margin-bottom: 64px;
    text-align: center;
    color: #fff;
  }
  .articles-items {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .articles-item {
      z-index: 2;
      position: relative;
      margin: 16px 0px;
      text-align: center;
      width: 30%;
      max-width: 600px;
      .img-title {
        margin-top: 12px;
        margin-bottom: 6px;
        text-align: left;
        color: #fff;
      }
      .img-desc {
        text-align: left;
      }
      .articles-img {
        position: relative;
        .cube {
          background-color: #171719;
          width: 24px;
          height: 24px;
          position: absolute;
          &.topleft {
            left: 0;
            top: 0;
          }
          &.topright {
            right: 0;
            top: 0;
          }
          &.bottomleft {
            left: 0;
            bottom: 0;
          }
          &.bottomright {
            right: 0;
            bottom: 0;
          }
        }
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
      &.middle {
        margin-left: 0px;
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    margin-bottom: 64px;
    .articles-items {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      .articles-item {
        width: 45%;
        &.middle {
          margin-left: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
    .isTitle {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .articles-items {
      .articles-item {
        width: 100%;
        margin: 16px 0px;
        .img-title {
          margin-top: 8px;
        }
        &.middle {
          margin-left: 0px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .isTitle {
      font-size: 16px;
    }
    .articles-items {
      .img-title {
        font-size: 13px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
  }
}
.video-box {
  position: relative;
  .line {
    width: 100%;
    height: 2px;
    background-color: #65616b;
  }
  .isTitle {
    margin-top: 84px;
    margin-bottom: 64px;
    text-align: center;
    color: #fff;
  }
  .video-box-inner {
    display: block;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 145px;
    .isArticle {
      z-index: 2;
      position: relative;

      text-align: center;
      width: 100%;
      max-width: 860px;
      .img-title {
        margin-top: 12px;
        margin-bottom: 6px;
        text-align: left;
        color: #fff;
      }
      .img-desc {
        text-align: left;
      }
      .articles-img {
        position: relative;
        .cube {
          background-color: #171719;
          width: 24px;
          height: 24px;
          position: absolute;
          &.topleft {
            left: 0;
            top: 0;
          }
          &.topright {
            right: 0;
            top: 0;
          }
          &.bottomleft {
            left: 0;
            bottom: 0;
          }
          &.bottomright {
            right: 0;
            bottom: 0;
          }
        }
        img {
          max-width: 100%;
          // width: 100%;
          height: auto;
        }
      }
    }
    .isVideo {
      z-index: 2;
      position: relative;
      margin-top: 44px;
      text-align: center;
      width: 100%;
      max-width: 860px;
      .cube {
        background-color: #171719;
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 9;
        &.topleft {
          left: 0;
          top: 0;
        }
        &.topright {
          right: 0;
          top: 0;
        }
        &.bottomleft {
          left: 0;
          bottom: 0;
        }
        &.bottomright {
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .isTitle {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .video-box-inner {
      margin-bottom: 64px;
    }
  }
  @media only screen and (max-width: 768px) {
    .isTitle {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}
.footer {
  background-color: #2a2a2d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 168px;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100vw;
    background: inherit;
    top: 0;
    left: -50vw;
    right: -50vw;
    margin-right: auto;
    margin-left: auto;
    z-index: -1;
  }
  .isLogo {
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      height: 33px;
      display: block;
      cursor: pointer;
    }
    .lang-btns {
      display: flex;
      margin-left: 42px;
      .lang-btn {
        cursor: pointer;
        width: 80px;
        height: 40px;
        background-color: #171719;
        color: #fff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .cube {
          background-color: #2a2a2d;
          width: 4px;
          height: 4px;
          position: absolute;
          z-index: 9;
          &.topleft {
            left: 0;
            top: 0;
          }
          &.topright {
            right: 0;
            top: 0;
          }
          &.bottomleft {
            left: 0;
            bottom: 0;
          }
          &.bottomright {
            right: 0;
            bottom: 0;
          }
        }
        .isContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0px 10px;
          opacity: 0.3;
          p {
            font-size: 16px;
          }
          img {
            display: block;
            max-width: 20px;
            height: auto;
          }
        }
        &.active {
          background-color: #6c32c2;
          .isContent {
            opacity: 1;
          }
        }
        &.lang-btn-ka {
        }
        &.lang-btn-en {
          margin-left: 12px;
        }
      }
    }
  }
  .isIcons {
    .icons-items {
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      img {
        cursor: pointer;
        max-width: 100%;
        height: 40px;
        margin-left: 8.5px;
        display: block;
      }
    }
    .copy {
      margin-top: 24px;
    }
  }
  @media only screen and (max-width: 1200px) {
    min-height: 48px;
    .isLogo {
      display: flex;
      img {
        max-width: 100%;
        height: 20px;
      }
      .lang-btns {
        display: flex;
        margin-left: 24px;
        .lang-btn {
          cursor: pointer;
          width: 46px;
          height: 24px;

          .isContent {
            padding: 0px 8px;
            p {
              font-size: 12px;
            }
            img {
              max-width: 12px;
            }
          }
          &.lang-btn-ka {
          }
          &.lang-btn-en {
            margin-left: 8px;
          }
        }
      }
    }
    .isIcons {
      .icons-items {
        img {
          max-width: 100%;
          height: 24px;
          margin-left: 8px;
          display: block;
        }
      }
      .copy {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .isLogo {
      img {
        max-width: 100%;
        height: 12px;
      }
      .lang-btns {
        display: flex;
        margin-left: 7px;
        .lang-btn {
          cursor: pointer;
          width: 46px;
          height: 24px;

          .isContent {
            padding: 0px 8px;
            p {
              font-size: 12px;
            }
            img {
              max-width: 12px;
            }
          }
          &.lang-btn-ka {
          }
          &.lang-btn-en {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
.empty-block {
  background-color: #2a2a2d;
  height: 58px;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100vw;
    background: inherit;
    top: 0;
    left: -50vw;
    right: -50vw;
    margin-right: auto;
    margin-left: auto;
    z-index: -1;
  }
}
</style>