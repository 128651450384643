<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import router from "@/router";
export default {
  components: {},
  created() {
    this.handleEnvLog();
    const html = document.documentElement;
    html.setAttribute(
      "lang",
      router.app._route.query.lang ?? process.env.VUE_APP_DEFAULT_LANGUAGE_CODE
    );
  },
  methods: {
    handleEnvLog() {
      if (process.env.VUE_APP_ENV_LOG === "true") {
        console.log("============= Env =============");
        console.log(process.env);
        const sessionToken = this.$route.query?.sessionToken;
        if (sessionToken) {
          localStorage.setItem("sessionToken", sessionToken);
        } else {
          localStorage.removeItem("sessionToken");
        }
      }
    },
  },
};
</script>

<style lang="scss">
html {
  background-color: #171719;
}
body {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #171719;
  position: relative;
}
.eb-main-wrapper {
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  background-color: #171719;
  height: 100vh;
  // top: 60px;
  top: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &::scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  @media only screen and (max-width: 600px) {
    &::-webkit-scrollbar {
      width: 3px !important;
    }
  }
}
</style>
