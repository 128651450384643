import Vue from "vue";
import App from "./App.vue";
import Axios from "axios";
import router from "./router";
import store from "./store";
import AxiosInterceptor from "./core/interceptors/index";
import AppInitializerService from "./core/services/app-initializer.service";
import moment from "moment";
import VuePlyr from "vue-plyr";
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Vue.prototype.moment = moment;
Vue.use(BootstrapVue);
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ["ended"],
});

AxiosInterceptor();
AppInitializerService.init(false).then(() => {
  new Vue({
    router,
    store,
    async created() {
      await AppInitializerService.init(true);
      this.$mount("#app");
    },
    render: (h) => h(App),
  });
});
