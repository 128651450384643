<template>
  <div class="video-inner">
    <!-- <p class="font-hl-medium text-font22 label"><span class="font-hl-medium text-font22" :class="{'font-hl-bold': isMdWidth}">YouTube</span> </p> -->
    <iframe src="https://www.youtube.com/embed/dr2HWwbR_BI?modestbranding=1&playsinline=1&rel=0" title="" frameborder="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "VideoComponent",
  props: {
    //isMdWidth: Boolean,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
// .label {
//   position: absolute;
//   text-align: center;
//   top: 100%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   // padding: 19px 81px;

//   @include interpolate(font-size, 320px, 1920px, 11px, 22px);
//   @include interpolate(padding-top, 320px, 1920px, 2px, 19px);
//   @include interpolate(padding-bottom, 320px, 1920px, 2px, 19px);
//   @include interpolate(padding-left, 320px, 1920px, 11px, 81px);
//   @include interpolate(padding-right, 320px, 1920px, 11px, 81px);
//   z-index: 9;
//   &::before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0%;
//     left: 0;
//     border-bottom: 3px solid #eb5315;
//     border-left: 4px solid #eb5315;
//     background-color: #ff4a00;
//     z-index: 1;
//     transform: skew(-15deg, 0deg);
//     border-radius: 20px 10px 20px 10px;
//     z-index: -1;
//   }
//   span {
//   }
//   @media only screen and (max-width: 768px) {
//     &::before {
//       content: "";
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0%;
//       left: 0;
//       border-bottom: 3px solid #d33e01;
//       border-left: 4px solid #d33e01;
//       background-color: #ff4a00;
//       z-index: 1;
//       transform: skew(-15deg, 0deg);
//       border-radius: 15px 7.5px 15px 7.5px;
//       z-index: -1;
//     }
//     span {
//       font-size: 16px;
//     }
//   }
//   @media only screen and (max-width: 600px) {
//     &::before {
//       content: "";
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0%;
//       left: 0;
//       border-bottom: 3px solid #eb5315;
//       border-left: 4px solid #eb5315;
//       background-color: #ff4a00;
//       z-index: 1;
//       transform: skew(-15deg, 0deg);
//       border-radius: 10px 5px 10px 5px;
//       z-index: -1;
//     }
//     span {
//       font-size: 12px;
//     }
//   }
// }
.video-inner {
  padding-top: 56.3%;
  border-radius: 16px;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 998px;
  width: 100%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
  }
  &:before {
    content: "";
    position: absolute;
    height: 70px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
  }
  &:after {
    content: "";
    position: absolute;
    height: 100px;
    width: 55px;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
  @media only screen and (max-width: 600px) {
    &:before {
      content: "";
      position: absolute;
      height: 50px;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 99999;
    }
  }
}
</style>
